import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue'),
        meta: {
            zhName: '主页',
        },
    },
    {
        path: '/wiseclinic',
        name: 'WiseClinic',
        component: () => import('../views/WiseClinicView.vue'),
        meta: {
            zhName: '智慧门诊',
        },
    },
    {
        path: '/wiseward',
        name: 'WiseWard',
        component: () => import('../views/WiseWardView.vue'),
        meta: {
            zhName: '智慧病房',
        },
    },
    {
        path: '/cloudmedicalimage',
        name: 'CloudMedicalImage',
        component: () => import('../views/CloudMedicalImageView.vue'),
        meta: {
            zhName: '云影像',
        },
    },
    {
        path: '/medicalwaste',
        name: 'MedicalWaste',
        component: () => import('../views/MedicalWasteView.vue'),
        meta: {
            zhName: '医废管理',
        },
    },
    {
        path: '/wiseaffairs',
        name: 'WiseAffairs',
        component: () => import('../views/WiseAffairsView.vue'),
        meta: {
            zhName: '智慧政务',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/AboutView.vue'),
        meta: {
            zhName: '关于我们',
        },
    },
]

const router = new VueRouter({
    routes,
})

export default router
