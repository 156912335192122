<template>
    <footer class="footer">
        <section class="top-wrapper">
            <div class="about">
                <h2>关于钦泽</h2>
                <ul class="info">
                    <li :key="index" v-for="(item, index) in infoList">
                        <div class="top">
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="bottom">{{ item.content }}</div>

                        <div v-show="index == 0" class="bottom" style="margin-top: 0.1rem">秦经理: 13220556572</div>
                    </li>
                </ul>
            </div>
            <div class="plans">
                <h2>解决方案</h2>
                <ul class="link">
                    <li :key="index" v-for="(item, index) in navList">
                        <button @click="navigate(item, index)">
                            <i class="fa fa-arrow-right c"></i>
                            <span>{{ item.meta.zhName }}</span>
                        </button>
                    </li>
                </ul>
                <div class="qr">
                    <img src="../assets/img/qr.png" alt="二维码" />
                    <p>扫码关注钦泽公众号</p>
                </div>
            </div>
        </section>
        <section class="bottom-wrapper">
            <p>山东钦泽软件有限公司</p>
            <p>Copyright © 2016-2019 chinzsoft.com. All rights reserved 备案号：鲁ICP备19010877号-1</p>
        </section>
    </footer>
</template>
<script>
    export default {
        name: 'FooterBarComponent',
        data() {
            return {
                infoList: [
                    {
                        icon: 'fa fa-phone',
                        title: '电话',
                        content: '0531-8591-8895',
                    },
                    {
                        icon: 'fa fa-envelope',
                        title: '邮箱',
                        content: 'marketing@chinzsoft.com',
                    },
                    {
                        icon: 'fa fa-map',
                        title: '地址',
                        content: '济南市天桥区K88名泉广场E3-512',
                    },
                ],
            }
        },
        computed: {
            navList() {
                return this.$router.options.routes
            },
        },
        methods: {
            navigate(item, index) {
                this.$store.commit('changeNavActivatedIndex', index)
                this.$router.push(item.path)
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .footer {
        background: #1d2024;
        color: #ccc;
        .top-wrapper {
            padding: 0.8rem 0.5rem;
            h2 {
                position: relative;
                color: #fff;
                font-weight: bold;

                &::before {
                    position: absolute;
                    left: 0.7rem;
                    bottom: -0.4rem;
                    content: '';
                    height: 2px;
                    width: 5px;
                    .bg;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -0.4rem;
                    content: '';
                    height: 2px;
                    width: 0.6rem;
                    .bg;
                }
            }

            .info {
                padding-top: 0.5rem;
                padding-left: 0.7rem;

                li {
                    padding: 0.3rem 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    &:last-child {
                        border-bottom: none;
                    }

                    .top {
                        padding-bottom: 0.2rem;

                        .fa {
                            display: inline-block;
                            width: 0.6rem;
                            font-size: 0.5rem;
                            margin-right: 0.3rem;
                        }
                        span {
                            color: #fff;
                        }
                    }
                    .bottom {
                        padding-left: 0.9rem;
                    }
                }
            }

            .plans {
                margin-top: 0.4rem;
                position: relative;

                .qr {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    img {
                        display: block;
                        margin: auto;
                        width: 2rem;
                        border: 5px solid #ffffff;
                    }

                    p {
                        margin-top: 0.4rem;
                    }
                }

                .link {
                    padding-top: 0.5rem;
                    padding-left: 0.7rem;
                    li {
                        margin-top: 0.5rem;
                        button {
                            i {
                                margin-right: 0.2rem;
                            }
                        }
                    }
                }
            }
        }
        .bottom-wrapper {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            padding: 0.5rem;
            p {
                text-align: center;
                line-height: 2;
                letter-spacing: normal;
            }
        }
    }
</style>
