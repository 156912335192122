<template>
    <div id="app">
        <HeaderBarComponent :transparent="transparent" />
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <FooterBarComponent />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                transparent: true,
            }
        },
        watch: {
            $route(val) {
                console.log(val)
                if (val.path.includes('home')) {
                    this.transparent = true
                } else {
                    this.transparent = false
                }
            },
        },
    }
</script>
