import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
//第三方插件
import { WOW } from 'wowjs'

import 'animate.css'
//公共组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import HeaderBarComponent from './components/HeaderBarComponent.vue'
import FooterBarComponent from './components/FooterBarComponent.vue'
import BannerComponent from './components/BannerComponent.vue'
import PageDisplayComponent from './components/PageDisplayComponent.vue'
//引入样式
import 'swiper/css/swiper.css'
import './assets/css/font-awesome.min.css'
import './assets/css/base.less'
import './assets/css/iconfontNew.css'

Vue.use(VueAwesomeSwiper)
Vue.component('HeaderBarComponent', HeaderBarComponent)
Vue.component('FooterBarComponent', FooterBarComponent)
Vue.component('BannerComponent', BannerComponent)
Vue.component('PageDisplayComponent', PageDisplayComponent)

Vue.prototype.$wow = new WOW({ live: false })

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
