<template>
    <header class="header">
        <section class="header-bar" :class="{ transparent: transparent }">
            <button class="btn" @click="toggleNav">
                <i class="fa fa-bars" v-if="!showList"></i>
                <i class="fa fa-times" v-else></i>
            </button>
            <div class="logo">
                <img src="../assets/img/logo.png" alt="山东钦泽软件有限公司" />
            </div>
        </section>
        <transition name="fade">
            <section class="nav-list" v-if="showList">
                <ul>
                    <li :class="{ active: index == navActivatedIndex }" :key="index" v-for="(item, index) in navList">
                        <button @click="navigate(item, index)">
                            {{ item.meta.zhName }}
                        </button>
                    </li>
                </ul>
            </section>
        </transition>
    </header>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        name: 'HeaderBarComponent',
        data() {
            return {
                showList: false,
            }
        },
        props: {
            transparent: {
                require: true,
                type: Boolean,
            },
        },
        computed: {
            navList() {
                return this.$router.options.routes
            },
            ...mapState({
                navActivatedIndex: state => state.navActivatedIndex,
            }),
        },
        methods: {
            toggleNav() {
                this.showList = !this.showList
            },
            navigate(item, index) {
                this.$store.commit('changeNavActivatedIndex', index)
                this.showList = false
                if (item.path != this.$route.path) {
                    this.$router.push(item.path)
                }
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .header {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;

        .header-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.2rem;
            position: relative;
            padding: 0 0.3rem;
            border-bottom: 1px solid #e7e7e7;
            background: #fff;

            &.transparent {
                background: transparent;
                border-bottom: none;
                box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.06);
            }

            .btn {
                position: absolute;
                left: 0.3rem;
                top: 50%;
                transform: translateY(-50%);

                i {
                    font-size: 0.4rem;
                }
            }

            .logo {
                img {
                    height: 0.6rem;
                }
            }
        }

        .nav-list {
            position: absolute;
            top: 1.2rem;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 0 0.3rem;
            li {
                border-bottom: solid 1px #e0e0e0;
                &.active {
                    button {
                        .c;
                    }
                }
                button {
                    display: block;
                    padding: 0.3rem 0;
                    font-weight: bold;
                }
            }
        }
    }
</style>
