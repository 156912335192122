<template>
    <section class="section page-display">
        <h2 class="sub-title">硬件/页面展示</h2>
        <p>支持各种尺寸屏幕 / 硬件终端对应场景完善 / 队列模板定制 / 成熟的系统机制 / 专业的精英团队 / 服务过上百家医院</p>
        <section class="swiper">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide class="slide" :key="index" v-for="(item, index) in pagesImg">
                    <div class="img-wrapper">
                        <img
                            :src="item"
                            alt=" 支持各种尺寸屏幕 / 硬件终端对应场景完善 / 队列模板定制 / 成熟的系统机制 /
      专业的精英团队 / 服务过上百家医院"
                        />
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </section>
    </section>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        name: 'PageDisplayComponent',
        props: {
            pagesImg: {
                type: Array,
                require: true,
            },
        },
        computed: {
            ...mapState({
                swiperOptions: state => state.swiperOptions,
            }),
        },
        methods: {
            toggleNav() {
                this.showList = !this.showList
            },
            navigate(item, index) {
                this.$store.commit('changeNavActivatedIndex', index)
                this.showList = false
                this.$router.push(item.path)
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .page-display {
        .bg-grey;
        .swiper {
            margin-top: 0.5rem;

            .swiper-container {
                padding-bottom: 0.8rem;
            }
        }
    }
</style>
